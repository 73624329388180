import request from '@/api'


// 新闻中心
export const newsList = params =>
  request({
    url: `/website/news/list`,
    method: 'post',
    data:params
  }).then(({ data }) => data)

  // 产品中心-产品列表
  export const productList = params =>
  request({
    url: `/website/product/list`,
    method: 'post',
    data:params
  }).then(({ data }) => data)


  // 产品中心-tab
  export const productTabs = params =>
  request({
    url: `/website/product/tab-list`,
    method: 'post',
    data:params
  }).then(({ data }) => data)
    