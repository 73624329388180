<template>
  <div class="footer">
    <div class="footerNav">
      <dl v-for="(itm, index) in navData" :key="index">
        <router-link :to="itm.link" tag="dt"
          ><i class="el-icon-arrow-right"></i>{{ itm.title }}</router-link
        >
        <dd>
          <ul>
            <router-link
              v-for="(subItm, subIndex) in itm.children"
              :key="subIndex"
              :to="subItm.link"
              tag="li"
              >{{ subItm.title }}</router-link
            >
          </ul>
        </dd>
      </dl>
      <div class="logo">
        <router-link :to="homeLink">
          <img src="@/assets/img/logo-nomal.png" />
        </router-link>
      </div>
    </div>
    <div class="copyRight">
      <p>
        Powered by 金建软件 © 2023 西安金建软件科技股份有限公司版权所有
        <a href="https://beian.miit.gov.cn/">陕ICP备11002459号-1</a> 
      </p>
      <!-- <p>业务联系电话：029-88403772</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "vFooter",
  data() {
    return {
      homeLink: "",
      navData: [],
    };
  },
  computed: {
    pageName() {
      return this.$store.getters["common/getPageName"];
    },
  },
  mounted() {
    this.homeLink = this.$store.state.common.homeLink;
    this.navData = this.$store.state.common.footerNav;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common.variable";
.footer {
  background: #fff;
  padding: 0 $page-space-120;
  min-height: 14.5vw;
  .footerNav {
    @extend %flex-box;
    border-top: 1px #eeeeee solid;
    padding: 50px 10px 15px;
    dl {
      @extend %flex-1;
      dt {
        font-size: $font-bigger-size;
        color: $font-color-content;
        line-height: 40px;
        padding-bottom: 5px;
        cursor: pointer;
        i {
          display: none;
          font-style: normal;
          float: right;
          padding-right: 18px;
          height: 18px;
          padding-top: 14px;
        }
      }
      dd {
        font-size: $font-base-size;
        color: $font-color-gray;
        line-height: 2;
        li {
          cursor: pointer;
        }
        li:hover {
          color: $font-color-hover;
        }
      }
    }
    .logo {
      width: 30%;
      height: 100%;
      padding: 0 20px;
      text-align: center;
      a {
        height: 100%;
        display: inline-block;
        img {
          box-sizing: border-box;
          padding: 8px 0;
          height: 120px;
          width: auto;
        }
      }
    }
  }
  .copyRight {
    //border-top: 1px #eeeeee solid;
    padding: 15px 0;
    text-align: center;
    color: $font-color-disable;
    line-height: 1.8;
    a{
      color: $font-color-gray;
    }
    a:hover{
      color: $font-color-primary;
    }
  }
}
@media (max-width: 1200px) {
  .footer {
    padding: 0 $page-space-80;
  }
}
@media (max-width: 1003px) {
  .footer {
    padding: 0 $page-space-60;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 0 $page-space-40;
    .footerNav {
      display: block;
      padding:25px 10px 10px;
      dl {
        flex: none;
        width: 100%;
        dt {
          font-size: $font-big-size;
          line-height: 36px;
          padding-bottom: 5px;
          color: $font-color-gray;
          i {
            display: block;
            padding-right: 0;
            height: 16px;
            padding-top: 12px;
          }
        }
        dd {
          display: none;
        }
      }
      .logo {
        display: none;
      }
    }
  }
}
@media (max-width: 640px) {
  .footer {
    padding: 0 $page-space-20;
  }
}
</style>
