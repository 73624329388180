<template>
  <div class="home">
    <banner></banner>
    <div class="main-body">
      <dl class="news-list">
        <dt class="title">新闻动态</dt>
        <dd class="main">
          <div class="list">
            <el-row :gutter="newsListConfig.gutter">
              <el-col
                v-for="(itm, index) of newsData"
                :key="index"
                :span="
                  newsListConfig.pageWidth <= 1003
                    ? newsListConfig.span
                    : index == 0
                    ? 16
                    : 8
                "
              >
                <news-card
                  :is-top="(!index && newsListConfig.pageWidth > 1003) ? true : false"
                  :newsData="itm"
                ></news-card>
              </el-col>
            </el-row>
          </div>
        </dd>
      </dl>
    </div>
    <!-- <div class="ad-wrap">
      <div class="text">
        <strong>阿里巴巴集团的使命是让天下没有难做的生意。</strong>
        我们旨在助力企业，帮助其变革营销、销售和经营的方式，提升其效率。我们为商家、品牌、零售商及其他企业提供技术设施以及营销平台，帮助其借助新技术的力量与用户和客户互动，并更高效地经营。我们还为企业提供领先的云设施和服务，以及更强的工作协作能力，促进其数字化转型并支持其业务增长。
      </div>
      <div class="ad-word">
        <img src="img/picture-word.png" />
      </div>
    </div> -->
  </div>
</template>

<script>
import banner from "@/components/banner.vue";
import newsCard from "@/components/news-card.vue";
import { newsList } from "@/api/notice"


export default {
  name: "homePage",
  data() {
    return {
      newsData: [],
      newsListConfig: {
        gutter: 20,
        span: 0,
        pageWidth: 1,
      },
    };
  },
  components: {
    banner,
    newsCard,
  },
  computed: {
    pageName() {
      return this.$store.getters["common/getPageName"];
    },
  },
  created() {
    this.getNewsList()
  },
  mounted() {
    this.$nextTick(function () {
      this.updateNewsListConfig();
    });
    window.addEventListener("resize", this.updateNewsListConfig);
  },
  methods: {
    async getNewsList() {
      let params = {
        type: 'xw',
        curPage: 1,
        pageSize: 5,
      }
      let { code, data } = await newsList(params)
      if (code === 200) {
        this.newsData = data.records
      }
    },
    updateNewsListConfig() {
      let pageWidth = document.body.clientWidth;
      this.$set(this.newsListConfig, "pageWidth", pageWidth);
      if (pageWidth <= 748) {
        this.$set(this.newsListConfig, "gutter", 10);
        this.$set(this.newsListConfig, "span", 24);
      } else if (pageWidth <= 1003) {
        this.$set(this.newsListConfig, "gutter", 15);
        this.$set(this.newsListConfig, "span", 12);
      } else {
        this.$set(this.newsListConfig, "gutter", 20);
        this.$set(this.newsListConfig, "span", 8);
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.scrollToTop);
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/common.variable";

.main-body {
  padding: 0 9vw;

  .news-list {
    padding: 30px 0;

    .title {
      font-size: 1.875rem;
      padding-bottom: 25px;
    }

    ::v-deep .list {
      .el-col {
        margin-bottom: 20px;
      }
    }
  }
}

.ad-wrap {
  @extend %absolute-parent;
  width: 100%;
  height: 1000px;
  background: url(@/assets/img/picture.jpeg) left top repeat;
  background-size: 100% 100%;

  .text {
    width: 60%;
    position: absolute;
    right: 100px;
    top: 120px;
    font-size: 1.2rem;
    color: #fff;
    line-height: 2;

    strong {
      display: block;
      font-size: 1.4rem;
      padding-bottom: 10px;
    }
  }

  .ad-word {
    width: 50%;
    position: absolute;
    left: 100px;
    bottom: 150px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  .ad-wrap {
    height: 640px;

    .text {
      width: 80%;
      position: absolute;
      right: 10%;
      top: 120px;
    }

    .ad-word {
      width: 50%;
      position: absolute;
      left: 100px;
      bottom: 150px;
    }
  }

  .list {
    display: flex;
  }

  .el-row {
    flex-wrap: wrap;

    .el-col:nth-child(1) {
      width: 100%;
    }

    // .el-col:nth-of-type(n+2) {
    //   width: 50%;
    // }
  }


}</style>
