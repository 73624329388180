import axios from 'axios'
import { request as req, response as res } from './http'

axios.defaults.timeout = 30000
axios.defaults.headers['Content-Type'] = 'application/json'

const request = axios.create({})

request.interceptors.request.use(...req(true))
request.interceptors.response.use(...res(true))
export default request
