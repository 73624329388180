<template>
  <div :class="{ top: isTop }" class="card-wrap" @click="toPdF" >
    <dl>
      <dt class="img"><img :src="newsData.thumbnail" /></dt>
      <dd class="content">
        <div class="title">{{ newsData.title }}</div>
        <div class="time">{{ newsData.uploadDate }}<i class="el-icon-arrow-right"></i></div>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: "newsCard",
  props: {
    newsData: {
      type: Object,
      require: true,
    },
    isTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    
    pageName() {
      return this.$store.getters["common/getPageName"];
    },
    bannerData() {
      return this.$store.getters["common/getBanner"];
    },
  },
  mounted() {},
  methods:{
    toPdF(){
      window.open(this.newsData.filePath)
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common.variable";
.card-wrap {
  display: block;
  width: 100%;
  background: #fff;
  color: $font-color;
  cursor: pointer;
  .img {
    @extend %absolute-parent;
    width: 100%;
    height: 240px;
    overflow: hidden;
    img {
      height: 100%;
      width: auto;
      min-width: 100%;
      transition: 1s; //动画过渡的更加顺滑
      transform: scale(1); //放大倍数
    }
  }
  .content {
    padding: 20px 35px;
    .title {
      @include text-overflow-ellipsis(2);
      font-size: 1.5rem;
      line-height: 32px;
      padding: 10px 0;
      height: 50px;
      margin-bottom: 80px;
    }
    .time {
      border-top: 1px #ccc solid;
      line-height: 20px;
      padding-top: 20px;
      i {
        display: block;
        float: right;
        font-style: normal;
        margin-top: 3px;
      }
    }
  }
  &.top {
    background: #fff;
    color: #000;
    .img {
      height: 350px;
    }
    .content {
      .title {
        height: 35px;
        margin-bottom: 0;
      }
      .time {
        border-top: 0;
        padding-top: 0;
        padding-bottom: 5px;
        i {
          display: none;
        }
      }
    }
  }
  &:hover {
    background: $bg-color-primary;
    color: #fff;
    .img {
      img {
        transition: 1s; //动画过渡的更加顺滑
        transform: scale(1.1); //放大倍数
      }
    }
  }
}
@media (max-width: 1200px) {
  .card-wrap {
    .img {
      height: 220px;
    }
    .content {
      padding: 15px 30px;
      .title {
        margin-bottom: 70px;
      }
      .time {
        line-height: 20px;
        padding-top: 15px;
      }
    }
    &.top {
      .img {
        height: 310px;
      }
    }
  }
}
@media (max-width: 1003px) {
  .card-wrap {
    .img {
      height: 9vw;
    }
    .content {
      padding: 15px 30px;
      .title {
        margin-bottom: 50px;
      }
    }
  }
}
@media (max-width: 768px) {
  .card-wrap {
    .img {
      height: 125px;
    }
    .content {
      padding: 10px 20px;
      .title {
        // margin-bottom: 30px;
        margin-bottom: 8px;
        font-size: 13px;
        line-height: 1.5;
      }
      .time {
        padding-top:10px;
      }
    }
  }
}
</style>
