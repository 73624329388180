import Vue from "vue";
//导入vue-router
import Router from "vue-router";
//使用路由插件
Vue.use(Router);

//导入组件
import home from "../pages/home";

//实例化路由
let router = new Router({
  mode: "hash",//1、hash:url带#号，2、history历史:url不带#号
  base: process.env.BASE_URL, //自动获取根目录路径
  routes: [
    {
      path: "/",
      redirect: '/home'
    },
    {
      path: "/home",
      name: "home",
      component: home
    },
    {
      path: "/news",
      name: "news",
      component: () => import("../pages/news"),
      meta: {
        title: "新闻公告"
      },
      children: [
        {
          path: "",
          redirect: '/news/announcements'
        },
        {
          path: "publish",
          name: "newsPublish",
          component: () => import("../pages/news/list"),
          meta: {
            title: "新闻发布"
          }
        },
        {
          path: "announcements",
          name: "newsAnnouncements",
          component: () => import("../pages/news/list"),
          meta: {
            title: "金建公告"
          }
        }
      ]
    },
    {
      path: "/news/detail/:id",
      name: "newsDetail",
      component: () => import("../pages/news/detail"),
      meta: {
        title: "新闻详情"
      }
    },
    {
      path: "/aboutus",
      name: "aboutus",
      component: () => import("../pages/aboutus"),
      meta: {
        title: "关于我们"
      },
      children: [
        {
          path: "",
          redirect: '/aboutus/profile'
        },
        {
          path: "profile",
          name: "aboutUsProfile",
          component: () => import("../pages/aboutus/content"),
          meta: {
            title: "企业概况"
          }
        },
        {
          path: "culture",
          name: "aboutUsCulture",
          component: () => import("../pages/aboutus/content"),
          meta: {
            title: "文化和愿景"
          }
        },
        {
          path: "milestone",
          name: "aboutUsMilestone",
          component: () => import("../pages/aboutus/content"),
          meta: {
            title: "发展历程"
          }
        },
        // {
        //   path: "friends",
        //   name: "aboutUsFriends",
        //   component: () => import("../pages/aboutus/content"),
        //   meta: {
        //     title: "金建朋友圈"
        //   }
        // }
      ]
    },
    {
      path: "/cncdp",
      name: "cncdp",
      component: () => import("../pages/cncdp"),
      meta: {
        title: "CNCDP"
      },
      children: [
        {
          path: "",
          redirect: '/cncdp/business'
        },
        {
          path: "team",
          name: "cncdpTeam",
          component: () => import("../pages/cncdp/list"),
          meta: {
            title: "开发者信息"
          }
        },
        {
          path: "business",
          name: "cncdpBusiness",
          component: () => import("../pages/cncdp/list"),
          meta: {
            title: "开发者业务"
          }
        },
      ]
    },
    {
      path: "/cncdp/detail/:id",
      name: "cncdpDetail",
      component: () => import("../pages/cncdp/detail"),
      meta: {
        title: "开发者详情"
      }
    },
    {
      path: "/service",
      name: "digitalService",
      component: () => import("../pages/service"),
      meta: {
        title: "数字金建"
      }
    },
    {
      path: "/products",
      name: "products",
      component: () => import("../pages/products"),
      meta: {
        title: "产品中心"
      },
      children: [
        {
          path: "",
          redirect: '/products/profile'
        },
        {
          path: "profile",
          name: "productsProfile",
          component: () => import("../pages/products/profile"),
          meta: {
            title: "产品展示"
          }
        },
        {
          path: "learning",
          name: "productsLearning",
          component: () => import("../pages/products/learning"),
          meta: {
            title: "常见问题"
          }
        }
      ]
    },
    {
      path: "/products/detail/:id",
      name: "productsDetail",
      component: () => import("../pages/products/detail"),
      meta: {
        title: "产品详情"
      }
    },
    {
      path: "/contactus",
      name: "contactUs",
      component: () => import("../pages/contactus"),
      meta: {
        title: "联系我们"
      },
      children: [
        {
          path: "",
          redirect: '/contactus/profile'
        },
        {
          path: "contact",
          name: "contactUsContact",
          component: () => import("../pages/contactus/content"),
          meta: {
            title: "联系方式"
          }
        },
        // {
        //   path: "team",
        //   name: "contactUsTeam",
        //   component: () => import("../pages/contactus/content"),
        //   meta: {
        //     title: "服务团队"
        //   }
        // }
      ]
    },
    {
      path: "**",
      component: home
    }
  ]
})
export default router;
