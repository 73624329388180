<template>
  <div class="banner">
    <div class="video-wrap" v-if="bannerData.type == 'video'">
      <video width="100%" autoplay loop muted v-if="bannerData.link">
        <source :src="bannerData.link" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="img-wrap" v-else>
      <img :src="bannerData.link" />
      <p class="discription" v-if="bannerData.discription">
        {{ bannerData.discription }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "vFooter",
  data() {
    return {};
  },
  computed: {
    pageName() {
      return this.$store.getters["common/getPageName"];
    },
    bannerData() {
      return this.$store.getters["common/getBanner"];
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common.variable";
.banner {
  @extend %absolute-parent;
  min-height: 64px;
  .video-wrap,
  .img-wrap {
    width: 100%;
    @extend %absolute-parent;
  }
  .img-wrap {
    img {
      width: 100%;
      height: auto;
    }
    .discription {
      position: absolute;
      left: $page-space-120;
      bottom: 3.3vw;
      z-index: 1000;
      font-size: 2vw;
      font-family: AlibabaPuHuiTi;
      color: #fff;

    
    }
  }
}
@media (max-width: 1200px) {
  .banner {
    .img-wrap {
      .discription {
        left: $page-space-80;
        bottom: 50px;
        font-size: 2.2rem;
      }
    }
  }
}
@media (max-width: 1003px) {
  .banner {
    .img-wrap {
      .discription {
        left: $page-space-60;
        bottom:40px;
        font-size: 2rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .banner {
    .img-wrap {
      .discription {
        left: $page-space-40;
        bottom: 30px;
        font-size: 1.8rem;
      }
    }
  }
}
@media (max-width: 640px) {
  .banner {
    .img-wrap {
      .discription {
        left: $page-space-20;
        bottom: 20px;
        font-size: 1.6rem;
      }
    }
  }
}
</style>
