// import NProgress from 'nprogress'
// import { serialize } from '@/util'
// import { getToken, removeToken } from '@/util/auth'
// import { getStore, clearStore } from '@/util/store'
// import store from '@/store'
// import router from '@/router/index'
// import { decrypt, removeEmpty, encrypt } from '@/util/index'
import { Message } from 'element-ui'
// import 'nprogress/nprogress.css'

// NProgress.configure({ showSpinner: true })
export function request() {
  return [
    config => {
      config.headers.Accept = 'application/json;charset=UTF-8'
      // NProgress.start()
      // const isToken = (config.data || {}).isToken === false
      // if (getStore({ name: 'token' }) && !isToken) {
      //   config.headers.Authorization = 'Bearer ' + getStore({ name: 'token' })
      // }
      // if (config.methods === 'post' && config.headers.serialize) {
      //   config.data = serialize(config.data)

      //   delete config.data.serialize
      // }

      return config
    },
    error => {
      Message.warning('请求超时')
      return Promise.reject(error)
    }
  ]
}
export function response() {
  return [
    res => {
      // const status = res.data.code >= 0 ? res.data.code : res.status
      // 10000|10001|10017 其他用户登录 强制下线
      // if (status === 10000 || status === 10001 || status === 10017) {
      //   // this.$store.commit('setShowDiag', {
      //   //   show: false,
      //   //   mes: res.data.message,
      //   //   sts: status,
      //   //   type: 2
      //   // })
      //   // // message({ type: 'warning', showClose: true, message: res.data.message || '请重新登录' })
      //   setTimeout(() => {
      //     // removeToken('token')
      //     // clearStore({ type: 'session' })
      //     window.localStorage.clear()
      //     window.location.href = '/login'
      //   }, 500)
      //   return
      // }
      // let dataStr = ''
      // if (res.data.encrypt) {
      //   // dataStr = decrypt('ADSFGDGASDGFDHJK', res.data.data)
      //   // let str = removeEmpty(dataStr)
      //   // dataStr = JSON.parse(str)
      // } else {
      //   dataStr = res.data.data
      // }
      // let Data = dataStr
      // if (res.config.responseType == 'blob') {
      //   res.data.status = status
      //   res.data.result = { ...Data, ...res.headers } || {}
      // } else {
      //   res.data.status = status
      //   res.data.result = Data || res.data.features || {}
      // }

      // if (status != 200) {
      //   let list = {
      //     mes: res.data.message,
      //     show: true,
      //     type: 2,
      //     sts: status
      //   }

      //   // store.commit('setShowDiag', list)
      //   // message({ type: 'warning', showClose: true, message: res.data.message || '请求失败' })
      //   return
      // }

      return res
    },
    error => {
      let responseStatus = error.request.status //code
      if (responseStatus != '502') {
        Message.error('请求失败')
        // let mes = error.response.data.message || '请求失败' //mes
        // let icon = String(responseStatus).slice(0, 1) == '5' ? 'el-icon-warning' : 'el-icon-info'
        // let status = String(responseStatus).slice(0, 1) == '5' ? 'error' : 'warning'
        // let type = String(responseStatus).slice(0, 1) == '5' ? '1' : '2'
        // if (router.history.current.path != '/login') {
        //   store.commit('setShowDiag', {
        //     mes,
        //     sts: responseStatus,
        //     show: true,
        //     type
        //   })
        // } else {
        //   store.state.loginPrompt = mes
        // }
      }
      return Promise.reject(new Error(error))
    }
  ]
}
