<template>
  <div class="to-top-btn" ref="toTopBtn" @click="backTop()"></div>
</template>

<script>
export default {
  name: "toTop",
  data() {
    return {
      homeLink: "",
      navData: [],
      timer: null,
    };
  },
  computed: {
    pageName() {
      return this.$store.getters["common/getPageName"];
    },
  },
  mounted() {
    this.scrollToTop()
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    // 返回顶部
    backTop() {
      let that = this;
      that.timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;

        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        that.isTop = true;
        if (osTop === 0) {
          clearInterval(that.timer);
        }
      });
    },

    // 滚动控制按钮显示
    scrollToTop() {
      let topBtn = this.$refs.toTopBtn;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let browserHeight = window.outerHeight;

      if (scrollTop > browserHeight) {

        topBtn.style.display = "block";
      } else {
        topBtn.style.display = "none";
        scrollTop = 720
      }
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common.variable";
.to-top-btn {
  width: 60px;
  height: 60px;
  background: url(@/assets/img/go-top.png) left top repeat;
  background-size: 100% 100%;
  cursor: pointer;
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 5000;
}
@media (max-width: 768px) {
  .to-top-btn {
    width: 40px;
    height: 40px;
    right: 40px;
    bottom: 40px;
    z-index: 5000;
  }
}
</style>
