<template>
  <div class="header" ref="header" :class="{ float: ['home','newsDetail'].includes(pageName)}">
    <div class="bg" :class="{ primary: isPrimary() }"></div>
    <div class="nav-wrap">
      <dl class="nav">
        <dt class="nav-but">
          <i class="el-icon-s-fold" @click="subMenuShowSwitch()"></i>
        </dt>
        <dd class="nav-list" ref="subMenu">
          <ul>
            <li
              v-for="(itm, index) in navData"
              :key="index"
              @click="linkClick(itm.link)"
            >
              {{ itm.title }}
            </li>
          </ul>
        </dd>
      </dl>
    </div>
    <div class="logo" @click="linkClick(homeLink)">
      <img src="@/assets/img/logo.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "vHeader",
  data() {
    return {
      homeLink: "",
      navData: [
        // {title:'首页',path:'/home'},
        // {title:'新闻公告',path:'/news'},
        // {title:'产品中心',path:'/products'},
        // {title:'关于我们',path:'/aboutus'},
        // {title:'数字金建',path:'/service'},
      ],
    };
  },
  computed: {
    pageName() {
      return this.$store.getters["common/getPageName"];
    },
  },
  mounted() {
    this.homeLink = this.$store.state.common.homeLink;
    this.navData = this.$store.state.common.navs;
    this.$nextTick(function () {
      if (document.body.clientWidth <= 768) {
        this.$refs.subMenu.style.display = "none";
      }
    });
    window.addEventListener("resize", this.updateSubMenuShow);
  },
  methods: {
    isPrimary() {
      return [
        "newsDetail",
        "cncdpDetail",
        "productsProfile",
        "productsLearning",
        "productsDetail",
      ].includes(this.pageName);
    },
    subMenuShowSwitch() {
      let target = this.$refs.subMenu;
      let display = target.style.display;
      if (display == "none") {
        target.style.display = "block";
      } else {
        target.style.display = "none";
      }
    },
    updateSubMenuShow() {
      if (document.body.clientWidth <= 768) {
        this.$refs.subMenu.style.display = "none";
      } else {
        this.$refs.subMenu.style.display = "block";
      }
    },
    linkClick(link) {
      this.updateSubMenuShow();
      if (link) {
        link.includes('https:')?window.open(link):this.$router.push(link);
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.scrollToTop);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common.variable";
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3.5vw;
  line-height: 3.5vw;
  z-index: 5000;
  &.float {
    position: fixed;
  }
  .bg,
  .nav-wrap,
  .logo {
    position: absolute;
    top: 0;
  }
  .bg,
  .nav-wrap {
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 $page-space-120;
    box-sizing: border-box;
    min-width: $page-min-width;
  }
  .bg {
    display: block;
    background: #000;
    filter: alpha(Opacity=0.5 * 100);
    -moz-opacity: 0.5;
    opacity: 0.5;
    z-index: 5001;
    &.primary {
      background: $bg-color-primary;
      filter: alpha(Opacity=0.9 * 100);
      -moz-opacity: 0.9;
      opacity: 0.9;
    }
  }
  .nav-wrap {
    z-index: 5002;
  }
  .nav {
    @extend %absolute-parent;
    box-sizing: border-box;
    width: 100%;
    .nav-but {
      display: none;
    }
    .nav-list {
      text-align: center;
      ul {
        display: block;
        margin: 0 auto;
        li {
          display: inline-block;
          size: 1.2rem;
          color: #fff;
          padding: 0 30px;
          cursor: pointer;
          //&.active {
          //  color: #ffff00;
          //}
        }
      }
    }
  }
  .logo {
    left: $page-space-120;
    height: 100%;
    padding: 0 20px;
    z-index: 5003;
    display: inline-block;
    cursor: pointer;
    img {
      box-sizing: border-box;
      padding: 8px 0;
      height: 100%;
      width: auto;
    }
  }
}
@media (max-width: 1200px) {
  .header {
    height: 54px;
    line-height: 54px;
    .bg,
    .nav-wrap {
      padding: 0 $page-space-80;
    }
    .logo {
      left: $page-space-80;
    }
    .nav-wrap {
      .nav-list {
        text-align: right;
      }
    }
  }
}
@media (max-width: 1003px) {
  .header {
    height: 44px;
    line-height: 44px;
    .bg,
    .nav-wrap {
      padding: 0 $page-space-60;
    }
    .logo {
      left: $page-space-60;
    }
  }
}
@media (max-width: 768px) {
  .header {
    height: 45px;
    line-height: 45px;
    .bg,
    .nav-wrap {
      padding: 0 $page-space-40;
    }
    .logo {
      left: 50%;
      transform: translatex(-50%);
      padding: 0 20px;
      img {
        padding: 3px 0;
      }
    }

    .nav-wrap {
      .nav-but {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 5004;
        color: #fff;
        font-size: 2rem;
        cursor: pointer;
      }
      .nav-list {
        position: absolute;
        top: 34px;
        right: 0px;
        text-align: left;
        background: #fff;
        padding: 0 0 10px;
        z-index: 8000;
        border: 1px $bg-color solid;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        display: none;
        width: 12rem;
        font-size: 1.3rem;
        text-align: center;
        ul {
          display: block;
          margin: 0 auto;
          li {
            display: block;
            size: 1.2rem;
            color: $font-color;
            padding: 0 15px;
            line-height: 3.5rem;
            cursor: pointer;
            //&.active {
            //  color: #ffff00;
            //}
          }
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .header {
    .bg,
    .nav-wrap {
      // padding: 0 $page-space-20;
      padding: 0 0;
      background-color: #1aa0ffb8;
    }
  }
}
</style>
