export default {
    namespaced: true,
    state: {
        currentPage: "home",
        currentPageTitle: "",
        homeLink: "/",
        navs: [
            {
                title: "首页",
                link: "/home",
            },
            {
                title: "公告新闻",
                link: "/news"
            },
            // {
            //     title: "CNCDP",
            //     link: "/cncdp"
            // },
            {
                title: "产品中心",
                link: "/products"
            },
            {
                title: "关于我们",
                link: "/aboutus"
            },
            {
                title: "数字金建",
                link: "/service"
            },
            {
                title: "在线咨询",
                link: "https://tccc.qcloud.com/web/im/index.html#/chat?webAppId=65334cb6792c3182ffc70145dc68f8c4"
            }
        ],
        footerNav: [
            {
                title: "关于我们",
                link: "/aboutus/profile",
                children: [
                    {
                        title: "企业概况",
                        link: "/aboutus/profile"
                    },
                    {
                        title: "文化和愿景",
                        link: "/aboutus/culture"
                    },
                    {
                        title: "发展历程",
                        link: "/aboutus/milestone"
                    },
                    // {
                    //     title: "金建朋友圈",
                    //     link: "/aboutus/friends"
                    // }
                ]
            },
            {
                title: "新闻公告",
                link: "/news/publish",
                children: [
                    {
                        title: "新闻发布",
                        link: "/news/publish"
                    },
                    {
                        title: "金建公告",
                        link: "/news/announcements"
                    }
                ]
            },
            // {
            //     title: "CNCDP",
            //     link: "/cncdp/business",
            //     children: [
            //         {
            //             title: "开发者信息",
            //             link: "/cncdp/business"
            //         },
            //         {
            //             title: "开发者业务",
            //             link: "/cncdp/team"
            //         },
            //     ]
            // },
            {
                title: "产品中心",
                link: "/products/profile",
                children: [
                    {
                        title: "产品展示",
                        link: "/products/profile"
                    }
                ]
            },
            {
                title: "联系我们",
                link: "/contactus/contact",
                children: [
                    {
                        title: "联系方式",
                        link: "/contactus/contact"
                    },
                    // {
                    //     title: "服务团队",
                    //     link: "/contactus/team"
                    // }
                    
                ]
            },
            {
                title: "常见问题",
                link: "/products/learning",
                children: [
                    {
                        title: "公司信息",
                        link: "/products/learning"
                    },
                    {
                        title: "业务信息",
                        link: "/products/learning"
                    },
                    {
                        title: "产品信息",
                        link: "/products/learning"
                    },
                    {
                        title: "服务信息",
                        link: "/products/learning"
                    },
                    
                ]
            },
        ],
        bannerData: [
            {
                type: "video",
                link: "https://sp.jjrj.com.cn/sv/e1064ee-18b1ce35db8/e1064ee-18b1ce35db8.mp4",
                discription: "",
                targetPages: ["home"]
            },
            {
                type: "img",
                link: require('@/assets/img/banner_news1.jpg'),
                discription: "公告及新闻",
                targetPages: ["newsAnnouncements","newsPublish"]
            },
            {
                type: "img",
                link: require('@/assets/img/banner_about.jpg'),
                discription: "二十年的发展史也是不断突破的创新史",
                targetPages: ["aboutUsProfile","aboutUsCulture","aboutUsMilestone","aboutUsFriends"]
            },
            {
                type: "img",
                link: require('@/assets/img/banner_cncdp.jpg'),
                discription: "精英团队打造专业化业务",
                targetPages: ["cncdpTeam","cncdpBusiness","cncdpDetail","aboutUsFriends"]
            },
            {
                type: "img",
                link: require('@/assets/img/banner3.jpg'),
                discription: "有我们更专业",
                targetPages: ["contactUsContact","contactUsTeam"]
            },
            {
                type: "img",
                link: require('@/assets/img/banner_products.jpg'),
                discription: "您值得信赖的品质",
                targetPages: ["productsProfile","productsLearning"]
            }
        ]
    },
    getters: {
        getPageName(state) {
            return state.currentPage;
        },
        getPageTitle(state) {
            return state.currentPageTitle;
        },
        getBanner(state) {
            let banner = state.bannerData.filter((item) => {
                return item.targetPages.includes(state.currentPage)
            })
            return banner.length > 0 ? banner[0]: {}
        }
    },
    mutations: {
        ["SET_CURRENT_PAGE"](state, payload) {
            state.currentPage = payload.currentPage;
        },
        ["SET_CURRENT_PAGE_TITLE"](state, payload) {
            state.currentPageTitle = payload.currentPageTitle;
        },
        ["SET_NAVS"](state, payload) {
            state.navs = payload.navs;
        }
    },
    actions: {
        getNavs(conText) {
            conText.commit("SET_NAVS", { navs: [] });
        }
    }
}