<template>
  <div id="app">
    <vHeader></vHeader>
    <router-view></router-view>
    <vFooter></vFooter>
    <goTop></goTop>
    <div class="customer_service">
      <img src="@/assets/img/team/service.png" />
      <a target="_blank"
        href="https://tccc.qcloud.com/web/im/index.html#/chat?webAppId=65334cb6792c3182ffc70145dc68f8c4">
        在线咨询
      </a>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import vFooter from "@/components/footer.vue";
import goTop from "@/components/to-top.vue";
export default {
  name: "App",
  components: {
    vHeader,
    vFooter,
    goTop,
  },
  watch: {
    $route(to) {
      this.$store.commit("common/SET_CURRENT_PAGE", {
        currentPage: to.name ? to.name : "",
      });
      this.$store.commit("common/SET_CURRENT_PAGE_TITLE", {
        currentPageTitle: to.meta.title ? to.meta.title : "",
      });
      if (!["aboutUsProfile", "aboutUsCulture", "aboutUsMilestone", "aboutUsFriends", "contactUsContact", "contactUsTeam"].includes(to.name)) {
        document.getElementById("body").scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common";

.customer_service {
  position: fixed;
  top: 740px;
  right: -78px;
  display: flex;
  align-items: center;
  transition: 0.2s;
  background-color: #fff;
  z-index: 9999;
  padding: 5px 10px;
  border-radius: 15px 0 0 15px;

  &:hover {
    right: 0px;
    transition: 0.3s;
  }

  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  a {
    color: #009fff;
    font-size: 16px;
  }
}
</style>
